<template>
  <section>
    <h1>Abmelden</h1>
    <p>Sie wurden erfolgreich abgemeldet.</p>

    <b-button variant="primary" @click="login">erneut anmelden</b-button>
  </section>
</template>

<script>
export default {
  name: 'Logout',
  methods: {
    login () {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss"></style>
